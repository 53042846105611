@keyframes pulse {
	0% {
		opacity: 1;
	}
    50% {
        opacity: .5;
    }
	100% {
		opacity: 1;
	}
}

@keyframes emit {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}


@keyframes shrink {
    100% {
        transform: scale(00);
    }
}

@keyframes font-fade {
    50% {
        opacity: .8;
    }
    100% {
        opacity: 1.0;
    }
}

@keyframes ocean-wave {
    0% {
		opacity: 1;
		transform: translateX(0px);
		animation: ease;
	}
	25% {
		transform: scale(.99);
	}
	50% {
		opacity: .5;
		transform: translateX(0%);
	}
	75%{
		transform: scale(1.01);
	}
	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

@keyframes blink {
    0%, 49.9%, 100%   { opacity: 0; }
    50%, 99.9% { opacity: 1; }
}

@keyframes half-blink {
	0% {
		opacity: 1,
	}
	50% {
		opacity: .5
	}
	100% {
		opacity: 1
	}
}

@keyframes fade-in {
    0% { opacity: 0; }
    100%  { opacity: 1; }
}

@keyframes slide-in-left {
	0% {
		right : 100vw,
	}
	100% {
		right : 0
	}
}

@keyframes fade-in-slide-in-left {
	0% {
		right : 100vw,
	}
	100% {
		right : 0
	}
}

.dropdown-toggle::after {
	display: none;
}